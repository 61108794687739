.cardList {
  width: 40em;
  max-width: 40em;
  max-width: 100vw;
  margin: 0 auto;
  margin-top: 2em;
}

.card {
  width: 100%;
}
